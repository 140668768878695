.mcd-nutrition__container {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  min-height: 0.0625rem;
  padding-right: 0;
  padding-left: 0;
  float: none;
  position: unset;
  text-align: center;
  padding: 0;
}

.mcd-nutrition__container .nutrition__content .item .value {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 300;
  color: #292929;
  font-family: speedee-light;
  margin-bottom: -10px;
  margin-bottom: -0.625rem;
}
